import { inView, animate } from "motion";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import Navigation from "./Blocks/Header/navigation";
import NiceSelect from "nice-select2";
import "nice-select2/dist/css/nice-select2.css";
import "./main.css";
import '@glidejs/glide/dist/css/glide.core.min.css';

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

const domReady = () => {
  Navigation();
  initModules();
  initAnimations();
};


/*

Each module should be treated as an individual block. 
Please pass each node as module param. 
Module path is case-sensitive. Wrong path will throw an error on dev server after running `npm run build`.

*/
function initModules() {
  // Slider
  const sliderNodes = document.querySelectorAll(".giantpeach-slider");
  if (sliderNodes.length) {
    import("./Blocks/Slider/slider.js").then(({default: Slider}) => {
      sliderNodes.forEach(slider => {
        Slider(slider);
      });
    });
  }

  // Testimonial Slider
  const testimonialSliderNodes = document.querySelectorAll(".giantpeach-testimonialslider");
  if (testimonialSliderNodes.length) {
    import("./Blocks/TestimonialSlider/testimonialSlider.js").then(({default: TestimonialSlider}) => {
      testimonialSliderNodes.forEach(slider => {
        TestimonialSlider(slider);
      });
    });
  }

  // Careousel Timeline
  const carouselTimelineNodes = document.querySelectorAll(".giantpeach-carouseltimeline");
  if (carouselTimelineNodes.length) {
    import("./Blocks/CarouselTimeline/carouselTimeline.js").then(({default: CarouselTimelineSlider}) => {
      carouselTimelineNodes.forEach(slider => {
        CarouselTimelineSlider(slider);
      });
    });
  }

  // Carousel Tiles
  const carouselTilesNodes = document.querySelectorAll(".giantpeach-carouseltiles");
  if (carouselTilesNodes.length) {
    import("./Blocks/CarouselTiles/carouselTiles.js").then(({default: CarouselTilesSlider}) => {
      carouselTilesNodes.forEach(slider => {
        CarouselTilesSlider(slider);
      });
    });
  }

  // Vertical Tiles
  const verticalTilesNodes = document.querySelectorAll(".giantpeach-verticaltiles");
  if (verticalTilesNodes.length) {
    import("./Blocks/VerticalTiles/verticalTiles.js").then(({default: CarouselVTilesSlider}) => {
      verticalTilesNodes.forEach(slider => {
        CarouselVTilesSlider(slider);
      });
    });
  }

  // News List
  const newsLatestNodes = document.querySelectorAll(".giantpeach-newslatest");
  if (newsLatestNodes.length) {
    import("./Blocks/NewsLatest/newsLatest.js").then(({default: NewsLatest}) => {
      newsLatestNodes.forEach(node => {
        NewsLatest(node);
      });
    });
  }

  // Ig Feed
  const igfeedNodes = document.querySelectorAll('.giantpeach-igfeed');
  if (igfeedNodes.length) {
    import("./Blocks/IgFeed/igfeed.js").then(({default: IgFeed}) => {
      igfeedNodes.forEach(igFeedNode => {
        IgFeed(igFeedNode);
      });
    });
  }

  // Banner sticky CTA
  const bannerCtaNodes = document.querySelectorAll('.giantpeach-bannerstickycta');
  if (bannerCtaNodes.length) {
    import("./Blocks/BannerStickyCTA/bannerStickyCTA.js").then(({default: BannerStickyCTA}) => {
      bannerCtaNodes.forEach(bannerStickyCTANode => {
        BannerStickyCTA(bannerStickyCTANode);
      });
    });
  }

  // Logo carousel
  const logoCarouselNodes = document.querySelectorAll('.giantpeach-logos');
  if (logoCarouselNodes.length) {
    import("./Blocks/Logos/logos.js").then(({default: Logos}) => {
      logoCarouselNodes.forEach(logoCarouselNode => {
        Logos(logoCarouselNode);
      });
    });
  }

  // Logo carousel
  const reviewsNodes = document.querySelectorAll('.giantpeach-reviews');
  if (reviewsNodes.length) {
    import("./Blocks/Reviews/reviews.js").then(({default: Reviews}) => {
      reviewsNodes.forEach(reviewCarouselNode => {
        Reviews(reviewCarouselNode);
      });
    });
  }


  // Modals
  const modalNodes = document.querySelectorAll('.modal');
  modalNodes.forEach(modalNode => {
    document.body.appendChild(modalNode);
  });

  document.querySelectorAll('[data-modal-open]').forEach(node => {
    node.addEventListener('click', e => {
      e.preventDefault();

      const targetModal = document.querySelector(node.dataset?.modalOpen);

      if (!targetModal) return;

      targetModal.style.opacity = 1;
      targetModal.style.visibility = 'visible';

      targetModal.querySelectorAll('.close, .modal-overlay').forEach(closeEl => {
        closeEl.addEventListener('click', e => {
          e.preventDefault();

          targetModal.style.opacity = 0;
          targetModal.style.visibility = 'hidden';

          // reload iframe
          const iframe = targetModal.querySelector('iframe');
          if (iframe) {
            iframe.src = iframe.src + '';
          }
        });
      });
    })
  });



  // Dropdowns - nice-select2.js
  const dropdownNodes = document.querySelectorAll('.niceselect');
  if (dropdownNodes.length) {
    dropdownNodes.forEach(dropdownNode => {
      new NiceSelect(dropdownNode, {searchable: false});
    });
  }

  // Banner offset with forms
  const banner = document.querySelector('.giantpeach-banner');

  if (banner && banner.nextElementSibling && banner.nextElementSibling.classList.contains('giantpeach-formblock')) {
    banner.classList.add('next-element-formblock');
  }
}

function initAnimations() {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: "none" },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(30px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  let transitionableBlocksProse = document.querySelectorAll(".transition-block-prose");
  inView(transitionableBlocksProse, ({ target }) => {
    target.classList.add('animated');

    return () => {
      target.classList.remove('animated');
    };
  });

  let transitionableBlocksClass = document.querySelectorAll(".transition-block-class");
  inView(transitionableBlocksClass, ({ target }) => {
    target.classList.add('animated');

    return () => {
      target.classList.remove('animated');
    };
  });

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opacity: 0, transform: "translateY(30px)" },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });
}

document.addEventListener("DOMContentLoaded", domReady);
